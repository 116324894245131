@import "./utils";

.card{
    background-color: transparent !important;
    border: none;
    .cardHeader{
        background: transparent;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #145a79;
        font-weight: bolder;
        position: relative;
        border: none;
        &::before{
            content: "";
            position: absolute;
            bottom: -10px;
            width: 5%;
            left: 47.5%;
            border-radius: 20px;
            border-bottom: 5px solid #5494af;
        }
    }
    .cardBody{
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        >a{
            margin: 2px 0;
            color: #145a79;
            text-decoration: none;
            transition: all 200ms ease;
            text-align: center;
            &:hover{
                text-decoration: underline;
            }
        }
        .alert{
            padding: 0.5rem;
            margin: 1rem;
        }
    }
    .loginForm{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        label{
            color: #145a79;
            font-weight: bold;
        }
        small{
            white-space: break-spaces;
        }
        &>div{
            min-width: 400px;
            max-width: 400px;
        }
    }
}

.customtooltip {
    position: relative;
    width: 100%;
    flex-direction: column;
    .tooltiptext {
        visibility: hidden;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        /* Position the tooltip */
        position: absolute;
        bottom: 100%;
        z-index: 1;
    }
    &:hover .tooltiptext.visible {
        visibility: visible;
    }
}

.modal, .card {
    .sendButton {
        @extend .defaultNuveeButton;
        align-self: center;
        padding: 0.5rem 5rem;
        &:disabled {
            background-color: #798286;
        }
    }
}

.maintenance {
    position: absolute;
    z-index: 10;
    background-color: white;
    border-radius: 10px;
    border: 10px solid #5494af;
    padding: 1em;
    text-align: center;
    max-width: 600px;
    min-height: 250px;
    p {
        color: #145a79;
        font-weight: bold;
    }
}

@media only screen and (max-width: 760px) {
    .card{
        .loginForm{
            &>div{
                min-width: unset;
                width: 100%;
            }
        }

        .cardBody{
            flex-wrap: wrap;
            a{
                width: 100%;
            }
        }

        .loginForm{
            width: 100% !important;
        }
    }

}
