@import "./utils";

.container{
    margin: 0 5vw;
}

.container, .containerFullWidth {
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    * {
        font-family: 'Montserrat', sans-serif;
    }
    
    .main{
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .title {
            text-align: center;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $nuvee_primary_color;
            font-weight: bolder;
            position: relative;
        }

        .description {
            text-align: center;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: black;
            font-weight: 600;
            position: relative;
        }

        .containerForm{
            display: flex;
            flex-wrap: wrap;
            background: #fff;
            padding: 10px;
            border-radius: 10px;
            box-shadow: 0px 0px 10px #00000051;
            margin-top: 40px;
        }

        .containerFormDiabete{
            display: flex;
            flex-wrap: wrap;
            background: #fff;
            padding: 10px;
            border-radius: 10px;
            box-shadow: 0px 0px 10px #00000051;
            margin-top: 40px;
        
            img{
                width: 30%;
                object-fit: cover;
                border-radius: 10px;
            }
        
            .subContainerFormDiabete{
                width: 70%;
                display: flex;
            }
            .inputLabel{
                input{
                    border-top: unset !important;
                    border-right: unset !important;
                    border-left: unset !important;
                    background-color: unset !important;
                    border-radius: unset !important;
                }
                input:focus{
                    outline: none;
                }
            
                input:focus-within{
                    border-bottom: 2px solid;
                }
            }
            .patientForm{
                .username {
                    flex: 1 1 40%;
                    input {
                        border-top: none;
                        border-left: none;
                        border-right: none;
                        background-color: unset;
                        border-radius: unset;
                    }
            
                    input:focus{
                        outline: none;
                    }
            
                    input:focus-within{
                        border-bottom: 2px solid;
                    }
                }
                .password {
                    
                    
                    input {
                        border-top: none;
                        border-left: none;
                        border-right: none;
                        background-color: unset;
                        border-radius: unset;
                    }
            
                    input:focus{
                        outline: none;
                    }
            
                    input:focus-within{
                        border-bottom: 2px solid;
                    }
                }
            }
            .send{
                flex: 1 1 100%;
                align-items: center;
                button{
                    background: linear-gradient(270deg, rgba(164,35,37,1) 0%, rgba(231,52,57,1) 50%);
                    //width: 50%;
                }
            }
        }
    }
}
.subtitle {
    color: $nuvee_primary_color;
    position: relative;
    padding: 1.5rem 0 1rem 0;
    font-size: 1.2em;
    font-weight: 600;
    width: 100%;
    div {
      content: "";
      position: absolute;
      bottom: 12px;
      width: 50px;
      left: 0;
      border-radius: 20px;
      border-bottom: 3px solid $nuvee_secondary_color_default ;
    }
}

.inputLabel{
    font-weight: bold;
    text-transform: uppercase;
    span{
        color: red;
    }
    input{
        width: 100%;
    }
}

.thematicListContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 1rem;

    h4 {
        @extend .subtitle
    }
    
    
    .thematicSelector{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        border-radius: 5px;
        &.error{
            border: 1px solid red;
        }
        .thematic {
            font-size: 0.7rem;
            margin: 1rem;
            padding: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: bold;
            color: $nuvee_primary_color;
            text-transform: uppercase;
            width: 200px;
            height: 200px;
            border: 1px solid $nuvee_primary_color;
            border-radius: 10px;
            background-size: cover;
    
            &:hover {
                cursor: pointer;
                box-shadow: 0 0 5px 5px grey;
            }
            &.chosen {
                box-shadow: 0 0 5px 5px grey;
            }
        }
    }

    label{
        @extend .inputLabel
    }

}

.patientForm {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 1rem;
    border-radius: 10px;
    gap: 1rem;
    h4 {
        @extend .subtitle;
    }
    
    .allFieldRequired{
        color: $nuvee_primary_color;
        font-style: italic;
        font-size:0.9em;
        
    }

    input[type=text],
    input[type=date] {

        &::placeholder {
            font-style: italic;
        }
    }

    .inputok {
        @extend .defaultNuveeInput;
        // border: 1px solid $nuvee_secondary_color;
    }

    .inputko {
        @extend .defaultNuveeInput;
        border: 1px solid red;
    }

    .selectLabel {
        display: flex;
        flex-direction: column;

        select{
            background-color: white;
        }
    }
    
    .email {
        flex: 1 1 40%;
        display: flex;
        flex-direction: column;
        margin: 10px;
        span{
            margin: 0 0 3px 0;
            color: black;
        }
        label{
            display: flex;
            flex-direction: column;
            margin: 0;
            width: 100%;
        }

        input {
            width: 100%;
            margin-bottom: 0.2rem;
        }

        .emailtaken {
            display: block;
            color: red;
        }
        .emailnottaken {
            display: none;
        }
    }

    .username {
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;

        input {
            width: 100%;
            margin-bottom: 0.2rem;
        }


        .usernametaken {
            display: block;
            color: red;
        }
        .usernamenottaken {
            display: none;
        }
    }
    .formContainer,
    .password {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 10px;
        .boxFlex {
            width: 100%;
            display: flex;
            flex-direction: column;
            input {
                border-top: none;
                border-left: none;
                border-right: none;
                background-color: unset;
                border-radius: unset;
            }
        }

        div {
            flex: 1 1 auto;
            min-width: 48%;
        }
        input {
            padding: 5px 10px;
        }
        span{
            border-top: none;
            border-left: none;
            border-right: none;
            border-radius: unset;
            background: unset;
        }

        label{
            max-width: 100%;
            margin: 0 0 3px 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .passwordError {
            display: block;
            color: red;
        }
        .passwordOk {
            display: none;
        }
    }

    .code{
        margin-top: 20px;
        margin-bottom: 0;
        width: 100%;
        flex: unset;
        display: flex;
        flex-direction: column;
        span{
            margin: 0 10px;
        }
    }
    label{
        @extend .inputLabel;
        max-height: 3em;
        margin: 10px;
        flex: auto;
    }
}

.subscriptionInformation {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2rem;
    padding: 2rem 1rem;
    border-radius: 10px;
    h4 {
        @extend .subtitle
    }
    span{
        padding: 20px;
        &.price{
            background-color: $nuvee_primary_color ;
            color: #fff;
            font-size: 1rem;
            font-weight: 600;
            border-radius: 10px;
        }
    }
    label{
        @extend .inputLabel
    }
}

.send{
    display: flex;
    flex-direction: column;
    width: 100%;
    div{
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100px;
        margin: 15px 0;

        .envelopeContainer{
            width: 115px;
            height: 115px;
            border: 3px solid $nuvee_primary_color;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            position: absolute;
            z-index: 6;
            background-color: white;
            img{
                width: 70px !important;
                height: 70px;
            }
        }

        p{
            width: 90%;
            margin: 0;
            right: 0;
            position: absolute;
            display: flex;
            align-items: center;
            border-radius: 10px;
            border: 3px solid $nuvee_primary_color;
            padding: 0.5rem 2rem;
            color: $nuvee_primary_color;
            font-weight: 600; 
            padding-left: 4rem;
        }
    }
    
    button{
        margin-top: 2rem;
        border-radius: 10px;
        border: none;
        padding: 0.5rem 7rem;
        color: white;
        font-weight: 600; 
         background-color: $nuvee_primary_color;
      

        &:hover {
            cursor: pointer;
            color: $nuvee_primary_color;
            background-color: $nuvee_background_color;
            box-shadow: 0 0 5px 1px grey;
        }
    }
    .loadRing {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        margin: auto;
    
        div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 64px;
            height: 64px;
            margin: 8px;
            border: 8px solid $nuvee_primary_color;
            border-radius: 50%;
            animation: loadRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: $nuvee_primary_color transparent transparent transparent;
            &:nth-child(1) {
                animation-delay: -0.45s;
            }
            &:nth-child(2) {
                animation-delay: -0.3s;
            }
            &:nth-child(3) {
                animation-delay: -0.15s;
            }
        }
    }
}

.sendError{
    margin: 25px 0 0 0;
    background: rgba(255, 0, 0, 0.775);
    border-radius: 10px;
    padding: 0.5rem 2rem;
    color: white;
    font-weight: 600;
}

  @keyframes loadRing {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

@media only screen and (min-width: 1281px) {
    .send{
        div{
            padding: 0 20px;
        }
    }
}

  @media only screen and (min-width: 601px) and (max-width: 900px) {
      .container{
          margin-left: 0;
          margin-right: 0;
      }

    .containerFormDiabete{
        flex-direction: column;
        img{
            width: 100% !important;
            height: 400px;
        }

        .subContainerFormDiabete{
            width: 100% !important;
        }
    }

    .send{
        div{
            .envelopeContainer{
                img{
                    width: 70px !important;
                }
            }
        }
    }
  }


  @media only screen and (max-width: 600px){
      .container{
          margin: 0;
          margin-top: 30px;
      }

      .containerFormDiabete{
          flex-direction: column;
          img{
              width: 100% !important;
          }

          .subContainerFormDiabete{
              width: 100% !important;
          }
      }

      .patientForm{
          .email{
            flex: 1 1 100%;
          }

          .username{
            flex: 1 1 100%
          }

          .formContainer,
          .password {
            flex: 1 1 100%;
            flex-direction: column;
            gap: 1rem;
          }
          .email{
            flex: 1 1 100%;
          }

          label{
            flex: 1 1 100% !important;
            width: 100%;
          }

          .code{
              margin-top: 20px;
              background-color: #fff;
              color: black;
              border: none;
              span{
                color: #fff !important;
              }
          }
      }

      .send{
          flex: 1 1 100%;

          div{
              height: unset;
              flex-direction: column;
              border: 3px solid $nuvee_primary_color;
              border-radius: 10px;
              background-color: $nuvee_primary_color;

            .envelopeContainer{
                position: relative;
                border: none;
                width: 90px;
                height: 90px;
                background-color: $nuvee_primary_color;
            }

            p{
                width: 100%;
                padding: 0.4rem;
                position: relative;
                border: none;
                color: #fff;
                text-align: justify;
            }
          }

          button{
              width: 100%;
              padding: 0.5rem 2rem;
          }
      }
  }