@import "./utils";

.modalContainer {
    .avatarIllustration {
        position: absolute;
        top: -9%;
        left: -4%;
        width: 25%;
        height: 25%;
        img {
            height: 100%;
        }
    }
    .modalTitle {
        padding-top: 1.5rem;
        text-align: center;
        font-size: 1.8rem;
        color: $nuvee-primary-color;
        font-weight: bold;
    }
    p {
        font-size: 1.1rem;
        color: $nuvee-primary-color;
        margin-top: 0.5rem;
        padding: 0 1rem;
        line-height: 2rem;
    }
    .modalFooter {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
        .linkPro {
            font-family: "MontSerrat", sans-serif;
            font-size: 1.2rem;
            font-weight: 600;
            margin-bottom: 2.5rem;
            color: $nuvee-primary-color;
            text-decoration: none;
            background-color: white;
            text-align: center;
            transition: all 200ms ease-in-out;
            cursor: pointer;
            &:hover {
                transform: scale(1.01);
            }
        }
        button {
            font-size: 1rem;
            font-weight: 500;
            border: none;
            cursor: pointer;
            background-color: #00bdff;
            padding: 0.5rem 1.5rem;
            margin-bottom: 1rem;
            min-width: 220px;
            box-shadow: 0 0 5px 1px silver;
            transition: all 200ms ease-in-out;
            &:hover {
                transform: scale(1.005);
            }
        }
        a {
            min-width: 220px;
            border: none;
            background-color: #00bdff;
            padding: 0.5rem 1.5rem;
            margin-bottom: 1rem;
        }
    }
}

@media screen and (max-width: 768px) {
    .modalContainer {
        .avatarIllustration {
            display: none;
            img {
                display: none;
            }
        }
    }
}
