@import "./utils";

.globalPortalContainer {
    margin: 1rem 1rem;
    .separator {
        width: 1px;
        height: 45vh;
        margin: 1rem 0.5rem 2rem 0.5rem;
        background-color: $nuvee_primary_color;
    }
    .globalAreasContainer {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        flex-direction: row;
        gap: 0.5rem;

        .titlesAreas {
            font-family: "MontSerrat", sans-serif;
            font-size: 1.4rem;
            font-weight: 600;
            margin-bottom: 2.5rem;
            color: $nuvee-primary-color;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            gap: 0.5rem;
            .iconsAreas {
                color: $nuvee-primary-color;
            }
        }
        .ButtonsAreas {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 1rem;
            .button {
                @extend .defaultNuveeButton;
                width: 100%;
                height: 3rem;
                &:hover {
                    border: $nuvee-primary-color 1px solid;
                }
            }
            .whiteButton {
                @extend .defaultNuveeButton;
                background-color: white;
                color: $nuvee-primary-color;
                width: 100%;
                height: 3rem;
                border: $nuvee-primary-color 1px solid;
                &:hover {
                    background-color: $nuvee-primary-color;
                    color: white;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .globalPortalContainer {
        margin: auto 1rem;
    }
}

@media screen and (max-width: 768px) {
    .globalPortalContainer {
        margin: 1rem 1rem;
        .separator {
            display: none;
        }
        .globalAreasContainer {
            align-items: center;
            flex-direction: column;
            gap: 2rem;
            .titlesAreas {
                margin-bottom: 1rem;
            }
        }
    }
}
