@import "./utils";

.card {
  background-color: transparent !important;
  border: none;
  .cardHeader {
    background: transparent;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #145a79;
    font-weight: bolder;
    position: relative;
    border: none;
    &::before {
      content: "";
      position: absolute;
      bottom: -10px;
      width: 5%;
      left: 47.5%;
      border-radius: 20px;
      border-bottom: 5px solid #5494af;
    }
  }
  .cardBody {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    .error {
      border: 1px solid rgb(192, 90, 90);
    }
    .success {
      border: 1px solid rgba(109, 185, 113, 0.705);
    }
    .passwordHintHide {
      display: none;
    }
    .passwordHintShow {
      display: inherit;
    }
    .passwordHintError {
      color: rgb(192, 90, 90);
    }
    label {
      color: #145a79;
      font-weight: bold;
    }
    .cguLabel {
      font-size: 0.8rem; 
      user-select: none;
      a {
        color: #145a79;
        font-weight: bolder;
      }
    }
    small {
      white-space: break-spaces;
    }
    & > div {
      min-width: 400px;
    }
  }
  .sendButton {
    @extend .defaultNuveeButton;
    align-self: center;
    padding: 0.5rem 5rem;
  }
}

.etape {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $nuvee_primary_color;
  font-weight: 400;
  position: relative;
  font-size: calc(1rem + 1vw);
  margin-top: 25px;
}

@media only screen and (max-width: 600px) {
  .card {
    .cardBody {
      :global {
        .w-50 {
          width: 100% !important;
          div {
            min-width: 100%;
          }
        }
      }
    }

    .sendButton {
      width: 100%;
    }
  }
}
