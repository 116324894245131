@import "./utils";

.card{
    background-color: transparent !important;
    border: none;
    .cardHeader{
        background: transparent;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #145a79;
        font-weight: bolder;
        position: relative;
        border: none;
        &::before{
            content: "";
            position: absolute;
            bottom: -10px;
            width: 5%;
            left: 47.5%;
            border-radius: 20px;
            border-bottom: 5px solid #5494af;
        }
    }
    .cardBody{
        background: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > a {
            margin: 2px 0;
            color: #145a79;
            text-decoration: none;
            transition: all 200ms ease;
            text-align: center;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .loginForm{
        display: flex;
        flex-direction: column;
        align-items: center;
        label{
            color: #145a79;
            font-weight: bold;
        }
        small{
            white-space: break-spaces;
        }
        &>div{
            min-width: 400px;
        }
    }
    .sendButton{
        @extend .defaultNuveeButton;
        align-self: center;
        padding: 0.5rem 5rem;
    }
}

@media only screen and (max-width: 600px) {
    .card{
        .cardBody{
            .loginForm{
                width: 100% !important;
                &>div{
                    min-width: 100%;
                }
            }
        }
    }
}