$nuvee_thematic                 : var(--nuvee_thematic, nutrition);

$nuvee_background_color         : var(--nuvee_background_color, #FFF);

$nuvee_primary_color            : var(--nuvee_primary_color, #145A79);
$nuvee_secondary_color          : var(--nuvee_secondary_color, #00A3A6);

$nuvee_text_black               : var(--nuvee_text_black, #000000);
$nuvee_text_gray                : var(--nuvee_text_gray, #828282);

$nuvee_secondary_color_default  : var(--nuvee_secondary_color_default, #00A3A6);
$nuvee_secondary_color_1        : var(--nuvee_secondary_color_1, #E6F4F2);
$nuvee_secondary_color_2        : var(--nuvee_secondary_color_2, #DAEAE7);
$nuvee_secondary_color_3        : var(--nuvee_secondary_color_3, #B9D1CC);
$nuvee_secondary_color_4        : var(--nuvee_secondary_color_4, #A1BEB8);
$nuvee_secondary_color_5        : var(--nuvee_secondary_color_5, #607D8B);
$nuvee_secondary_color_6        : var(--nuvee_secondary_color_6, #546D79);
$nuvee_secondary_color_7        : var(--nuvee_secondary_color_7, #D9EAE7);
$nuvee_footer_background_color  : var(--nuvee_footer_background_color, #3E3D3D);

//$answer_right_color             : var(--answer_right_color, #00A3A6);
$answer_right_color             : var(--answer_right_color, #0DA642);
//$answer_wrong_color             : var(--answer_wrong_color, #F29086);
$answer_wrong_color             : var(--answer_wrong_color, #E73439);
$answer_right_deep_color        : var(--answer_right_deep_color, #145A79);
$answer_right_smooth_color      : var(--answer_right_smooth_color, #00A3A6);
$answer_wrong_deep_color        : var(--answer_wrong_deep_color, #a7584f);
$answer_wrong_smooth_color      : var(--answer_wrong_smooth_color, #F29086);
$answer_background_lightblue    : var(--answer_background_lightblue, #f5fffd);

$image_width: var( --image_width, 140px );
$image_height: var( --image_height, 120px );

$image_width_mini: var( --image_width_mini, 80px );
$image_height_mini: var( --image_height_mini, 80px );

@font-face {
    font-family: "Montserrat";
    src: url("../../public/fonts/Montserrat/Montserrat-Thin.ttf");
    font-weight: 100;
}

@font-face {
    font-family: "Montserrat";
    src: url("../../public/fonts/Montserrat/Montserrat-Light.ttf");
    font-weight: 300;
}

@font-face {
    font-family: "Montserrat";
    src: url("../../public/fonts/Montserrat/Montserrat-Regular.ttf");
    font-weight: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../../public/fonts/Montserrat/Montserrat-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Montserrat";
    src: url("../../public/fonts/Montserrat/Montserrat-SemiBold.ttf");
    font-weight: 600;
}

@font-face {
    font-family: "Montserrat";
    src: url("../../public/fonts/Montserrat/Montserrat-Bold.ttf");
    font-weight: bold;
}



.nutrition{
    --nuvee_thematic: nutrition;
    --nuvee_background_color:#FFF;
    --nuvee_primary_color: #145A79;
    --nuvee_secondary_color: #00A3A6;

    --nuvee_secondary_color_default:#00A3A6;
    --nuvee_secondary_color_1 : #E6F4F2;
    --nuvee_secondary_color_2 : #DAEAE7;
    --nuvee_secondary_color_3 : #B9D1CC;
    --nuvee_secondary_color_4 : #A1BEB8;
    --nuvee_secondary_color_5 : #607D8B;
    --nuvee_secondary_color_6 : #546D79;
    --nuvee_secondary_color_7 : #D9EAE7;

    --nuvee_footer_background_color: #3E3D3D;
}

.chirurgie{
    --nuvee_thematic: chirurgie;
    --nuvee_background_color:#FFF;
    --nuvee_primary_color: #145A79;
    --nuvee_secondary_color: #00A3A6;

    --nuvee_secondary_color_default:#F29086;
    --nuvee_secondary_color_1 : #F8EDEC;
    --nuvee_secondary_color_2 : #F7E3E1;
    --nuvee_secondary_color_3 : #DDC8C6;
    --nuvee_secondary_color_4 : #DBBBB8;
    --nuvee_secondary_color_5 : #C4A5A2;
    --nuvee_secondary_color_6 : #8E7A78;
    --nuvee_secondary_color_7 : #F7E3E1;

    --nuvee_footer_background_color: #3E3D3D;
}

.maternite{
    --nuvee_thematic: maternite;
    --nuvee_background_color:#FFF;
    --nuvee_primary_color: #145A79;
    --nuvee_secondary_color: #00A3A6;

    --nuvee_secondary_color_default:#C24191;
    --nuvee_secondary_color_1 : #F7EDF4;
    --nuvee_secondary_color_2 : #E0BFD3;
    --nuvee_secondary_color_3 : #DEC5D5;
    --nuvee_secondary_color_4 : #DBBBCF;
    --nuvee_secondary_color_5 : #CCA9BF;
    --nuvee_secondary_color_6 : #84737E;
    --nuvee_secondary_color_7 : #F8ECF4;

    --nuvee_footer_background_color: #3E3D3D;
}

.diabete{
    --nuvee_thematic: diabete;
    --nuvee_background_color:#FFF;
    --nuvee_primary_color: #145A79;
    --nuvee_secondary_color: #00A3A6;

    --nuvee_secondary_color_default:#E73439;
    --nuvee_secondary_color_1 : #FAE8E9;
    --nuvee_secondary_color_2 : #E5C2C3;
    --nuvee_secondary_color_3 : #E2C2C3;
    --nuvee_secondary_color_4 : #E0AEAF;
    --nuvee_secondary_color_5 : #E0AEAF;
    --nuvee_secondary_color_6 : #6E5C5C;
    --nuvee_secondary_color_7 : #FAE8E9;
    
    --nuvee_footer_background_color: #3E3D3D;
}

.defaultNuveeButton{
    border-radius: 10px;
    border: none;
    padding: 0.5rem 2rem;
    color: white;
    font-weight: 600; 
    background-color: $nuvee_primary_color;
    transition: all 300ms;
    &:hover {
        color: $nuvee_primary_color;
        background-color: white;
        box-shadow: 0 0 10px 1px silver;
        cursor: pointer;
    }

    &:active, &:focus{
        background-color: #145A79;
        color: white;
    }
}

.defaultNuveeInput{
    border: #CECACA solid 1px;
    border-radius: 5px;
    background-color: #F7F8F8;
    padding: 5px 10px;
}

.secondaryNuveeInput{
    border: $nuvee_secondary_color_default solid 1px;
    background-color: white;
    padding: 5px 10px;
    color: $nuvee_text_gray;
}
