@import "~bootstrap/scss/bootstrap";

.main {
    min-height: 100vh;
    width:100%;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    background-color: #fff;
    * {
        font-family: 'Montserrat', sans-serif;
    }
}

.nav{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    padding: 2rem;
    .logo{
        padding: 0;
        margin: 2rem 0 0 1em;
      img{
        width: 350px;
      }
    }
  }
  
footer.footerDiv {
    width: 100%;
    padding: 1rem;
    background-color: #145A79;
    margin-top: auto;
    font: normal normal 600 16px/20px Montserrat, sans-serif;

    svg{
        fill:white;
    }

    .footerContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li{
                padding: 7px;
                color: white;
                font-weight: 400;
                display: flex;
                svg{
                    max-width: 15px;
                    margin: 0 10px 0 0;
                }
            }
        }

        .leftContainer {
            display: flex;
            justify-content: center;
            font-size: 15px;
            flex: 1;
            svg{
                max-height: 14px;
            }
            a:visited,a{
                color: white;
                text-decoration: none;
                transition: 0.3s;
            }
            a:hover{
                color: #b6b6b6;
            }
        }
        .middleContainer {
            display: flex;
            flex-direction: row;
            height: 100%;
            justify-content: center;
            flex: 3;
        }
        .rightContainer {
            display: flex;
            justify-content: center;
            flex-direction: row;
            flex: 1;
            svg{
                max-width: 40px;
                max-height: 40px;
                margin: 0 1rem;
            }
            a{
                transition: 0.2s;
            }
            a:hover{
                transform: scale(0.9);
            }
        }
    }
}

.copyright{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    font-size: 14px;
    color: #145a74;
}

.card{
    background-color: transparent !important;
    border: none;
    .maintenance{
        color: #ff6d6d !important;
    }
    .cardHeader{
        background: transparent;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #145a79;
        font-weight: bolder;
        position: relative;
        border: none;
        &::before{
            content: "";
            position: absolute;
            bottom: -10px;
            width: 5%;
            left: 47.5%;
            border-radius: 20px;
            border-bottom: 5px solid #5494af;
        }
    }
    .cardBody{
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        >a{
            margin: 2px 0;
            color: #145a79;
            text-decoration: none;
            transition: all 200ms ease;
            text-align: center;
            &:hover{
                text-decoration: underline;
            }
        }
        .alert{
            padding: 0.5rem;
            margin: 1rem;
        }
    }

}

.maintenanceContainer{
    display: flex;
    flex-wrap: wrap;

    .titleMaintenance{
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px;
        padding-left: 60px;
        flex-direction: column;
        img{
            width: 200px;
            position: absolute;
            top: 40px;
            left: 60px;
        }
        h1{
            font-size: 50px;
            color: #145A79;
            font-weight: 400;
            margin-bottom: 80px;
            margin-top: 60px;
            span{
                font-weight: 700;
            }
        }
    }

    .imgMaintenance{
        width: 50%;
    }

    .networkMaintenance{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        h2{
            width: 100%;
            color: #145A79;
            font-weight: 400;
            margin-bottom: 30px;
            span{
                font-weight: 700;
            }
        }

        div{
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background: #145a74;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px 40px 20px 40px;

            a{
                width: 40%;
                height: 40%;
                svg{
                    color: white;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        div:nth-child(2){
            margin-left: 0;
        }

    }
}

@media only screen and (min-width: 700px) and (max-width: 1025px) {
    .maintenanceContainer{
        .titleMaintenance{
            img{
                left: 80px !important;
            }
        }
    }
}

@media only screen and (max-width: 1025px) {
    .alertContainer {
        width: 100%;
        bottom: 0 !important;
    }
    .maintenanceContainer{
        flex-direction: column-reverse;
        .titleMaintenance{
            width: 100%;
            padding: 20px;
            img{
                top: 30px;
                left: 22px;
            }

            h1{
                font-size: 28px;
                margin-bottom: 40px;
                text-align: center;
            }
        }

        .imgMaintenance{
            width: 100%;
            img{
                padding: 0px;
                width: 100%;
            }
        }

        .networkMaintenance{
            justify-content: center;
            h2{
                text-align: center;
            }

            div{
                width: 70px;
                height: 70px;
                margin: 20px;
            }
        }
    }
}

@media screen and (max-width: 1025px) {
    footer.footerDiv {
        .footerContainer {
            gap: 1rem;
            flex-wrap: wrap;
            flex-direction: row;
            padding: 0 0 2rem 0;
            .leftContainer {
                flex: auto;
                flex-basis: 48%;
                img {
                    max-width: 150px;
                }
            }
            .middleContainer {
                align-items: center;
                order: -1;
                gap: 0.5rem;
                flex: auto;
                flex-basis: 100%;
            }
            .rightContainer {
                font-size: 9px;
                font-weight: 100;
                flex: auto;
                flex-basis: 48%;
            }
        }
    }
    
}


@keyframes pulse {
    from {
        transform: scale3d(1, 1, 1);
        // transform: rotateY(0)
    }
  
    50% {
        transform: scale3d(1.05, 1.05, 1.05);
        // transform: rotateY(180deg)
    }
  
    to {
        transform: scale3d(1, 1, 1);
        // transform: rotateY(360deg)
    }
}

@keyframes rotate {
    from {
        transform: rotateY(0)
    }
  
    50% {
        transform: rotateY(180deg)
    }
  
    to {
        transform: rotateY(360deg)
    }
}

.containerSpinnerDebug {
    filter: blur(10px);
}

.spinnerContainer {
    position: fixed;
    background-color: white;
    z-index: 9;
    width: 100%;
    height: 100%;
    &.debug {
        background-color: rgba(0,0,0,.1);
    }
    .spinnerPulse {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            animation: rotate 3s infinite;
            height: 100px;
            width: 100px;
        }
        &::before {
            content: '';
            display: block;
            width: 140px;
            height: 140px;
            border-radius: 50%;
            cursor: pointer;
            box-shadow: 0 0 5px 10px rgba(20,90,121, .5);
            animation: pulse 2s infinite;
            position: absolute;
        }
    }
}

.alertContainer {
    position: fixed;
    z-index: 9;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%);
}